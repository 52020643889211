@font-face {
    font-family: 'Helvetica Neue LT Pro';
    src: url('../fonts/HelveticaNeueLTPro-Th.woff') format('woff2'),
        url('../fonts/HelveticaNeueLTPro-Th.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Helvetica Neue LT Pro';
    src: url('../fonts/HelveticaNeueLTPro-Lt.woff2') format('woff2'),
        url('../fonts/HelveticaNeueLTPro-Lt.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Helvetica Neue LT Pro';
    src: url('../fonts/HelveticaNeueLTPro-Md.woff2') format('woff2'),
        url('../fonts/HelveticaNeueLTPro-Md.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Helvetica Neue LT Pro Extended';
    src: url('../fonts/HelveticaNeueLTPro-ThEx.woff2') format('woff2'),
        url('../fonts/HelveticaNeueLTPro-ThEx.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Helvetica Neue LT Pro Extended';
    src: url('../fonts/HelveticaNeueLTPro-UltLtEx.woff2') format('woff2'),
        url('../fonts/HelveticaNeueLTPro-UltLtEx.woff') format('woff');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Helvetica Neue LT Pro Extended';
    src: url('../fonts/HelveticaNeueLTPro-LtEx.woff2') format('woff2'),
        url('../fonts/HelveticaNeueLTPro-LtEx.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Helvetica Neue LT Pro Extended';
    src: url('../fonts/HelveticaNeueLTPro-Ex.woff2') format('woff2'),
        url('../fonts/HelveticaNeueLTPro-Ex.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Helvetica Neue LT Pro Extended';
    src: url('../fonts/HelveticaNeueLTPro-MdEx.woff2') format('woff2'),
        url('../fonts/HelveticaNeueLTPro-MdEx.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Helvetica Neue LT Pro Extended';
    src: url('../fonts/HelveticaNeueLTPro-BdEx.woff2') format('woff2'),
        url('../fonts/HelveticaNeueLTPro-BdEx.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Helvetica Neue LT Pro Extended';
    src: url('../fonts/HelveticaNeueLTPro-HvEx.woff2') format('woff2'),
        url('../fonts/HelveticaNeueLTPro-HvEx.woff') format('woff');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Helvetica Neue LT Pro Extended';
    src: url('../fonts/HelveticaNeueLTPro-BlkEx.woff2') format('woff2'),
        url('../fonts/HelveticaNeueLTPro-BlkEx.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Canela';
    src: url('../fonts/Canela-Light.woff2') format('woff2'),
        url('../fonts/Canela-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Canela';
    src: url('../fonts/Canela-LightItalic.woff2') format('woff2'),
        url('../fonts/Canela-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}
